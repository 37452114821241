import { Sheet, Typography } from '@mui/joy';
import { ReactComponent as ProtectionIcon } from 'assets/icons/protection-icon.svg';

export const QrFooter = () => (
  <Sheet
    sx={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      p: 1.5,
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: 'rgba(240, 242, 250, 0.7)',
    }}
  >
    <ProtectionIcon />
    <div>
      <Typography
        level="h5"
        sx={{ lineHeight: '20px', letterSpacing: '0.02em' }}
      >
        TrueToForm does not store photos or videos
      </Typography>
      <Typography
        level="body3"
        sx={{
          '& a': {
            textDecoration: 'none',
            color: 'common.mediumBlue',
            cursor: 'pointer',
          },
          fontSize: 12,
        }}
      >
        Learn more about{' '}
        <a
          href="https://www.truetoform.fit/help-center#scan-data-privacy"
          target="_blank"
          rel="noreferrer"
        >
          scan data and privacy
        </a>{' '}
        on our help center
      </Typography>
    </div>
  </Sheet>
);
