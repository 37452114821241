import { db } from 'db/base';
import { getDoc, doc } from 'firebase/firestore';

const prefersWebApp = async (clientId: string) => {
  const docRef = doc(db, 'clientIds/prefers_web_app');
  const docSnap = await getDoc(docRef);
  return docSnap.data()?.[clientId] ?? false;
};

/**
 * This function will create a QR link with client ID and session ID embedded.
 * @param clientId
 * @returns Promise<string>
 */
export async function createSessionQRLink(clientId: string) {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get('sessionId');
  const dynamicLinkDomain = process.env.REACT_APP_DYNAMIC_LINKS_DOMAIN;
  const apiKey = process.env.REACT_APP_DYNAMIC_LINKS_API_KEY;
  const link = `${process.env.REACT_APP_DYNAMIC_LINK}?clientId=${clientId}&sessionId=${sessionId}`;
  const prefersWebAppLink = await prefersWebApp(clientId);
  if (prefersWebAppLink) {
    return `${process.env.REACT_APP_PWA_URL}/send-avatar?clientId=${clientId}&sessionId=${sessionId}`;
  }
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`;
  const body = {
    dynamicLinkInfo: {
      domainUriPrefix: dynamicLinkDomain,
      link,
      iosInfo: {
        iosBundleId: process.env.REACT_APP_IOS_BUNDLE_ID,
        iosAppStoreId: process.env.REACT_APP_IOS_APP_STORE_ID,
      },
    },
    suffix: {
      option: 'SHORT',
    },
  };

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  const { shortLink } = data;

  if (!shortLink) {
    throw new Error('Could not create short link');
  }
  return shortLink;
}
